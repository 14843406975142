import AsterImage from "./images/aster.png";
import MhCmsImage from "./images/mh-cms.png";
import SeguroImage from "./images/seguro.png";
import QDesignImage from "./images/qdesign.png";
import FindDocImage from "./images/finddoc.png";
import BarGraphImage from "./images/bargraph.png";
// import ElHajAliImage from "./images/elhajali.png";
import FoodFinderImage from "./images/foodfinder.png";
import MhPortfolioImage from "./images/mh-portfolio.png";
import CottonChamberImage from "./images/cotton-chamber.png";
// import PersonalPortfolioImage from "./images/my-portfolio.png";

const projects = [
  {
    title: "Cotton Chamber",
    description: "Clothes Manufacturing Company Website",
    tech: "Next.js, Scss",
    link: "https://www.cottonchamber.com/",
    image: CottonChamberImage,
    keywords: ["nextjs", "next.js", "sass", "scss", "javascript", "emailjs", "email.js"],
  },
  {
    title: "Aster Consultancy",
    description: "Travel and immigration consultancy agency booking website",
    tech: "Next.js, Scss",
    link: "https://www.asterconsultancy.com/",
    image: AsterImage,
    keywords: ["nextjs", "next.js", "sass", "scss", "javascript"],
  },
  {
    title: "FoodFinder",
    description:
      "Health and fitness website including a recipes search API, calorie calculator, and meal planning.",
    tech: "React.js, Scss",
    repo: "https://github.com/Osama-NA/Health-and-Fitness-Website",
    link: "https://foodfinderdemo.netlify.app/",
    image: FoodFinderImage,
    keywords: ["react.js", "reactjs", "scss", "sass", "javascript"],
  },
  {
    title: "M.H. Portfolio",
    description: "Creative photography portfolio with interactive animations.",
    tech: "React.js, Styled Components",
    repo: "https://github.com/Osama-NA/MH-Portfolio",
    link: "https://mohammadhammoudii.netlify.app/",
    image: MhPortfolioImage,
    keywords: [
      "react.js",
      "reactjs",
      "styled components",
      "styled-components",
      "javascript",
    ],
  },
  {
    title: "M.H. Portfolio CMS & Backend",
    description:
      "Content Management System for Mohammad Hammoudi's portfolio, built with MERN Stack",
    tech: "Node.js, MongoDB, React.js, Styled Components",
    repo: "",
    link: "https://mh-cms-demo.netlify.app/",
    image: MhCmsImage,
    keywords: [
      "react.js",
      "reactjs",
      "styled components",
      "styled-components",
      "nodejs",
      "node.js",
      "mongodb",
      "cloudinary",
      "javascript",
    ],
  },
  {
    title: "FindDoc",
    description: "Doctor appointment and chatting system. Helps patients discover the right doctor.",
    tech: "React.js, Node.js, MongoDB, Styled Components",
    repo: "https://github.com/Osama-NA/FindDoc",
    link: "https://finddoc-demo.netlify.app/",
    image: FindDocImage,
    keywords: ["reactjs", "react.js", "styled components", "styled-components", "javascript", 'node.js', 'mongodb', 'cloudinary'],
  },
  {
    title: "Q Design Automobile",
    description:
      "Q Design Automobile is one of the primary car care centers in Kuwait.",
    tech: "HTML, CSS, JavaScript",
    link: "https://qdesignautomobile.com/",
    image: QDesignImage,
    keywords: ["html", "css", "javascript"],
  },
  {
      title: 'Seguro',
      description: 'Create private chat rooms and start a chat by sending someone a secure link to the chat room.',
      tech: ' Node.js, HTML, CSS, JavaScript',
      repo: 'https://github.com/Osama-NA/Seguro',
      link: 'https://seguro.glitch.me/',
      image: SeguroImage,
      keywords: ['nodejs', 'node.js', 'html', 'css', 'js', 'handlebars', 'hbs', 'socket.io', 'socketio', 'javascript']
  },
  {
    title: "Bar Graph",
    description: "Easily generates a bar graph that can be downloaded as a PNG image.",
    tech: "React.js, Styled Components",
    repo: "https://github.com/Osama-NA/Bar-Graph-Creator",
    link: "https://bargraphcreator.netlify.app/",
    image: BarGraphImage,
    keywords: [
      "react.js",
      "reactjs",
      "styled components",
      "styled-components",
      "javascript",
    ],
  },
  // {
  //   title: "Elhaj-Ali Portfolio",
  //   description: "Photography portfolio with a minimalistic UI",
  //   tech: "Next.js, Scss",
  //   repo: "https://github.com/Osama-NA/Hajj-Ali-Portfolio",
  //   link: "https://elhajali.netlify.app/",
  //   image: ElHajAliImage,
  //   keywords: ["nextjs", "next.js", "sass", "scss", "javascript"],
  // },
  // {
  //   title: "Personal Portfolio",
  //   description: "My personal portfolio built using React.js with a minimal UI design.",
  //   tech: "React.js, Styled Components",
  //   repo: "https://github.com/Osama-NA/My-Personal-Portfolio",
  //   link: "/",
  //   image: PersonalPortfolioImage,
  //   keywords: [
  //     "react.js",
  //     "reactjs",
  //     "styled components",
  //     "styled-components",
  //     "javascript",
  //   ],
  // },
];

export default projects;