import React from 'react'
import Instagram from './instagram.svg'
// import Twitter from './twitter.svg'
import LinkedIn from './linkedin.svg'

const Icons = () => {
  return (
    <div className="icons">
      <Icon 
        icon={Instagram}
        url="https://www.instagram.com/osama_a67/"
      />
      <Icon 
        icon={LinkedIn}
        url="https://www.linkedin.com/in/osama-hajj-ali/"
      />
      {/* <Icon 
        icon={Twitter}
        url="https://twitter.com/DevByOsama/"
      /> */}
    </div>
  )
}

const Icon = ({icon, url}) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={icon} alt="Socials Link Icon" />
    </a>
  )
}

export default Icons